import cn from 'classnames';
import { type ReactNode } from 'react';

export interface BadgeProps {
	testId?: string;
	children: ReactNode;
	color?: 'hvac' | 'lime' | 'sky' | 'pink' | 'red' | 'amber';
	className?: string;
	size?: 'large' | 'medium' | 'small';
}

const Badge = (props: BadgeProps) => {
	const {
		testId = 'Badge',
		children,
		color = 'hvac',
		className,
		size = 'large',
	} = props;

	return (
		<div
			data-testid={testId}
			className={cn(
				'inline-block px-2 py-1',
				{
					'bg-gradient-to-r from-royal-orange/5 via-rose-bonbon/5 to-vivid-cerulean/5':
						color === 'hvac',
					'bg-lime-50': color === 'lime',
					'bg-sky-50': color === 'sky',
					'bg-pink-50': color === 'pink',
					'bg-red-50': color === 'red',
					'bg-amber-50': color === 'amber',
					rounded: size === 'large' || size === 'medium',
					'rounded-sm': size === 'small',
				},
				className,
			)}
		>
			<span
				className={cn('font-medium tracking-normal', {
					'bg-gradient-to-r from-royal-orange via-rose-bonbon to-vivid-cerulean bg-clip-text text-transparent':
						color === 'hvac',
					'text-lime-600': color === 'lime',
					'text-sky-600': color === 'sky',
					'text-pink-600': color === 'pink',
					'text-red-600': color === 'red',
					'text-amber-600': color === 'amber',
					'text-xs lg:text-sm': size === 'large',
					'text-xs': size === 'medium',
					'text-xxs': size === 'small',
				})}
			>
				{children}
			</span>
		</div>
	);
};

export default Badge;
