'use client';

import { triggerChatMessage } from '@/helpers/chatlio/triggerChatMessage';

import QuotePicture from '@/public/img/heros/InfoRow-Infinity-HVAC-Technician.webp';
import QuoteScorePicture from '@/public/img/infinity-lite/ProductCard_QuoteScore.webp';
import ChatPicture from '@/public/img/infinity-lite/ProductCard_Chat.webp';
import CTACardButton from './CTACardButton/CTACardButton';
import CTACardLink from './CTACardLink/CTACardLink';
import React from 'react';

const ctaContent = [
	{
		key: 'quote',
		type: 'HVAC Replacement',
		title: 'Replace your HVAC',
		content:
			'Fair upfront pricing within 72 hours, a customized solution for your home from top licensed contractors in your area.',
		buttonText: 'Learn more',
		imgSrc: QuotePicture,
		imgAlt: 'HVAC technician fixing unit',
		href: '/quote/',
	},
	{
		key: 'quotescore',
		type: 'QuoteScore',
		title: 'Get a quote scored',
		content:
			'Have a quote already? Get it scored by our team of HVAC experts to understand the good, the bad, and the unclear.',
		buttonText: 'Score my quote',
		imgSrc: QuoteScorePicture,
		imgAlt: 'Reviewing a Quote Score report',
		href: '/quotescore/',
	},

	{
		key: 'chat',
		type: 'Live Chat',
		title: 'Chat with an Advisor',
		content:
			'Our friendly team of Technical Advisors is ready to lend a hand as you navigate your way through the HVAC world.',
		buttonText: 'Get help now',
		imgSrc: ChatPicture,
		imgAlt: 'Chat with an HVAC technician',
		button: true,
	},
];

export interface DualCTACardsProps {
	testId?: string;
	showQuoteScore?: boolean;
	showQuote?: boolean;
	showChat?: boolean;
	image?: boolean;
}

const DualCTACards = (props: DualCTACardsProps) => {
	const {
		testId = 'DualCTACards',
		showQuoteScore,
		showQuote,
		showChat,
		image = false,
	} = props;

	return (
		<div
			data-testid={testId}
			className="mb-10 flex flex-col justify-between gap-7 lg:mb-20 lg:flex-row"
		>
			{ctaContent.map((cta) => {
				if (
					!(cta.key === 'quotescore' && showQuoteScore) &&
					!(cta.key === 'quote' && showQuote) &&
					!(cta.key === 'chat' && showChat)
				) {
					return null;
				} else {
					return (
						<React.Fragment key={cta.key}>
							{cta.href && (
								<>
									{cta.key === 'quote' && (
										<CTACardLink
											badgeColor="hvac"
											content={cta.content}
											buttonText={cta.buttonText}
											title={cta.title}
											type={cta.type}
											image={image}
											imgSrc={cta.imgSrc}
											imgAlt={cta.imgAlt}
											href={cta.href}
										/>
									)}
									{cta.key === 'quotescore' && (
										<CTACardLink
											badgeColor="lime"
											content={cta.content}
											buttonText={cta.buttonText}
											title={cta.title}
											type={cta.type}
											image={image}
											imgSrc={cta.imgSrc}
											imgAlt={cta.imgAlt}
											href={cta.href}
										/>
									)}
								</>
							)}
							{cta.button && (
								<CTACardButton
									badgeColor="sky"
									content={cta.content}
									buttonText={cta.buttonText}
									title={cta.title}
									type={cta.type}
									image={image}
									imgSrc={cta.imgSrc}
									imgAlt={cta.imgAlt}
									onClick={() =>
										triggerChatMessage('Hello, how can we help you today?')
									}
								/>
							)}
						</React.Fragment>
					);
				}
			})}
		</div>
	);
};

export default DualCTACards;
