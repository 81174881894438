export default function logError(error: Error) {
	if (typeof window === 'undefined') {
		return;
	}
	// Check for new relic.
	if (window.newrelic) {
		// Report the error to new relic.
		window.newrelic.noticeError(error);
	}
	// Log the error to the console.
	console.error(error);
	// Re-throw the error.
	return error;
}
