import cn from 'classnames';

import Image, { StaticImageData } from 'next/image';
import { IconArrowRight } from '../../icons';
import { Badge } from '../../Badge';
import Link from 'next/link';

export interface CTACardLinkProps {
	testId?: string;
	badgeColor: 'hvac' | 'lime' | 'sky' | 'pink' | 'red' | 'amber';
	buttonText: string;
	content: string;
	href: string;
	image?: boolean;
	imgSrc?: StaticImageData;
	imgAlt?: string;
	title: string;
	type: string;
}

const CTACardLink = (props: CTACardLinkProps) => {
	const {
		testId = 'CTACardLink',
		badgeColor = 'hvac',
		buttonText = '',
		content,
		image = false,
		imgSrc,
		imgAlt,
		href,
		title,
		type,
	} = props;

	return (
		<Link
			data-testid={testId}
			className={cn(
				`group flex w-full flex-col rounded-3xl text-left tracking-tight shadow-xl
transition-all ease-in-out hover:scale-105 hover:shadow-2xl lg:w-1/2 lg:rounded-5xl`,
				{
					'shadow-pink-500/10 hover:shadow-pink-500/10': badgeColor === 'hvac',
				},
				{
					'shadow-lime-600/10 hover:shadow-lime-600/10': badgeColor === 'lime',
				},
			)}
			href={href}
		>
			{image && !!imgSrc && !!imgAlt && (
				<Image
					src={imgSrc}
					alt={imgAlt}
					className="rounded-t-3xl lg:rounded-t-5xl"
				/>
			)}
			<div className="px-6 py-8 lg:px-8 lg:py-10">
				<Badge className="mb-1 lg:mb-2" color={badgeColor}>
					{type}
				</Badge>
				<div className="mb-3 text-balance text-2xl font-semibold tracking-tight lg:mb-4 lg:text-4xl">
					{title}
				</div>
				<p className="mb-4 text-balance text-xl font-normal lg:mb-6 lg:text-2xl">
					{content}
				</p>
				<div
					className={cn(
						'group flex items-center text-xl tracking-tight lg:text-2xl',
						{
							'text-pink-500 active:text-pink-600 [@media(hover:hover)]:group-hover:text-pink-600':
								badgeColor === 'hvac',
						},
						{
							'text-lime-600 active:text-lime-700 [@media(hover:hover)]:group-hover:text-lime-700':
								badgeColor === 'lime',
						},
					)}
				>
					{buttonText}
					<IconArrowRight
						className={cn(
							'ml-1 h-5 w-4 lg:h-6 lg:w-6',
							{
								'fill-pink-500 group-hover:fill-pink-600':
									badgeColor === 'hvac',
							},
							{
								'fill-lime-600 group-hover:fill-lime-700':
									badgeColor === 'lime',
							},
						)}
					/>
				</div>
			</div>
		</Link>
	);
};

export default CTACardLink;
