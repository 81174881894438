'use client';

import { TreatmentsWithConfig } from '@/helpers/split/types';
import { useEffect } from 'react';

export interface HeapAddUserPropertiesProps<T extends string> {
	treatments?: TreatmentsWithConfig<T>;
}

/**
 * Client-side component that adds user properties to the Heap analytics platform based on the provided Split.IOtreatments.
 */
const HeapAddUserProperties = <T extends string>(
	props: HeapAddUserPropertiesProps<T>,
) => {
	const { treatments } = props;

	useEffect(() => {
		if (treatments && window.heap) {
			const userProperties = Object.entries(treatments).reduce(
				(acc, [key, value]) => {
					const treatment = (value as SplitIO.TreatmentWithConfig).treatment;
					acc[`SplitTreatment ${key}`] = treatment;
					return acc;
				},
				{} as Record<string, string>,
			);
			window.heap.addUserProperties(userProperties);
		}
	}, [treatments]);

	return null;
};

export default HeapAddUserProperties;
