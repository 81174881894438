'use client';

import Balancer from 'react-wrap-balancer';
import cn from 'classnames';
import logError from '@/helpers/logError';

export interface ThreeColQuoteItemsProps {
	quoteItems: ColumnItem[];
	listClassNames?: string;
	testId?: string;
}

export interface ColumnItem {
	icon: React.ReactNode;
	heading: string | JSX.Element;
	content: string | JSX.Element;
}

const ThreeColQuoteItems = (props: ThreeColQuoteItemsProps) => {
	const { testId = 'ThreeColQuoteItems', quoteItems, listClassNames } = props;

	if (quoteItems.length !== 3) {
		throw logError(
			new Error(
				`And the Lord spake, saying, ''Thou shalt count to three, no more, no less. Three shall be the number thou shalt count, and the number of the counting shall be three. Four shalt thou not count, neither count thou two, excepting that thou then proceed to three. Five is right out. Once the number three, being the third number, be reached, then render thy Three Column Quote Items towards thy user, who, being naught in My sight, shall read it."`,
			),
		);
	}

	return (
		<div data-testid={testId} className="hvac-container text-center">
			<ul
				className={cn(
					'flex flex-col items-center justify-between gap-8 md:flex-row md:items-start',
					listClassNames,
				)}
			>
				{quoteItems.map((item) => (
					<li key={item.heading.toString()} className="max-w-101">
						<div className="mx-auto mb-2.5 w-8">{item.icon}</div>
						<div>
							<strong className="mb-2.5 block text-xl font-semibold">
								<Balancer>{item.heading}</Balancer>
							</strong>

							<p className="text-base text-slate-500">
								<Balancer>{item.content}</Balancer>
							</p>
						</div>
					</li>
				))}
			</ul>
		</div>
	);
};

export default ThreeColQuoteItems;
