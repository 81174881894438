'use client';

import cn from 'classnames';

import Image, { StaticImageData } from 'next/image';
import { IconArrowRight } from '../../icons';
import { Badge } from '../../Badge';

export interface CTACardButtonProps {
	testId?: string;
	badgeColor: 'hvac' | 'lime' | 'sky' | 'pink' | 'red' | 'amber';
	buttonText: string;
	content: string;
	image?: boolean;
	imgSrc?: StaticImageData;
	imgAlt?: string;
	onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	title: string;
	type: string;
}

const CTACardButton = (props: CTACardButtonProps) => {
	const {
		testId = 'CTACardButton',
		badgeColor = 'sky',
		buttonText = '',
		content,
		image = false,
		imgSrc,
		imgAlt,
		onClick,
		title,
		type,
	} = props;

	return (
		<button
			data-testid={testId}
			className={cn(
				`group flex w-full flex-col rounded-3xl text-left tracking-tight shadow-xl transition-all
ease-in-out hover:scale-105 hover:shadow-2xl lg:w-1/2 lg:rounded-5xl`,
				{ 'shadow-sky-500/10 hover:shadow-sky-500/10': badgeColor === 'sky' },
			)}
			onClick={onClick}
		>
			{image && !!imgSrc && !!imgAlt && (
				<Image
					src={imgSrc}
					alt={imgAlt}
					className="rounded-t-3xl lg:rounded-t-5xl"
				/>
			)}
			<div className="px-6 py-8 lg:px-8 lg:py-10">
				<Badge className="mb-1 lg:mb-2" color={badgeColor}>
					{type}
				</Badge>
				<div className="mb-3 text-balance text-2xl font-semibold tracking-tight lg:mb-4 lg:text-4xl">
					{title}
				</div>
				<p className="mb-4 text-balance text-xl font-normal lg:mb-6 lg:text-2xl">
					{content}
				</p>
				<div
					className={cn(
						'group flex items-center text-xl tracking-tight lg:text-2xl',
						{
							'text-sky-600 active:text-sky-700 [@media(hover:hover)]:group-hover:text-sky-700':
								badgeColor === 'sky',
						},
					)}
				>
					{buttonText}
					<IconArrowRight
						className={cn('ml-1 h-5 w-4 lg:h-6 lg:w-6', {
							'fill-sky-600 group-hover:fill-sky-700': badgeColor === 'sky',
						})}
					/>
				</div>
			</div>
		</button>
	);
};

export default CTACardButton;
