import { CHATLIO_HOMEOWNER_WIDGET_ID } from './constants';
import { resetChatlioTrigger } from './resetChatlioTrigger';

export const triggerChatMessage = (message: string) => {
	if (!window._chatlio) return;

	// switch to homeowner chat
	window._chatlio.switchWidget?.(CHATLIO_HOMEOWNER_WIDGET_ID);

	// switching resets chat. per the docs, we have to listen for a new ready event before doing anything
	document.addEventListener(
		'chatlio.ready',
		() => {
			if (window._chatlio.messages().length > 0) {
				window._chatlio.open();
				return;
			}
			// OFFLINE:
			// We have an offline form that should be shown to the user when all agents
			// are offline. In order for this form to show, we need to call open()

			// MOBILE:
			// according to an engineer at Chatlio, they no longer intend to support
			// triggers on mobile. Alternatively, we simply open the chat on mobile.

			// show first since it might be hidden due to screen size
			window._chatlio.show();

			if (!window._chatlio.isOnline() || window.innerWidth <= 480) {
				window._chatlio.open();
			} else {
				// otherwise (desktop view AND online) trigger a message
				resetChatlioTrigger();
				window._chatlio.trigger({
					message: message,
					after: 0,
				});
			}
		},
		{ once: true },
	);
};
